<template>
	<div>
		<qy-top-nav title="选择城市仓" />

		<div :style="{height: containerHeight,overflow:'auto'}">

			<div class="listDetails"  v-for="item in dataList" style="display: flex;color:#333;">
				<div style="width: 75px;height:75px;margin-right: 10px;flex-shrink: 0;">
					<img :src="getImgUrl(item.img)" />
				</div>
				<div @click="setProxyStg(item)">
					<div style="font-size: 18px;font-weight: bold;;"><van-icon name="points"style="position: relative;top:2px;left:0px;" /> {{item.name}}</div>
					<div style="margin:5px 0;">
						<span>{{item.leader}} </span>
						<span><van-icon name="phone-o"style="position: relative;top:2px;left:0px;" /> {{item.phone}}</span>
					</div>
					<div style="color: #999;font-size: 13px;">
						<van-icon name="location-o" style="position: relative;top:2px;left:2px;"/>	{{item.province+item.city+item.county+" "+item.address}}
					</div>
				
					
				</div>
			</div>
			<van-list v-model="loading" :finished="finished" :offset="offset" finished-text="没有更多了" @load="onLoad">

			</van-list>
		</div>
	</div>
</template>
<script type="text/javascript">
	import request from "@/utils/request";
	import {
		Toast
	} from 'vant';
	export default {
		name: "proxyStg",
		components: {},
		data() {
			return {
				loading: false,
				finished: false,
				offset: 50,
				containerHeight: 0,

				pageSize: 10,
				pageNum: 1,
				dataList: [],
				tempObj: {},
			}
		},
		methods: {

			onLoad() {
				//是否已经登录
				let isLogin = this.isLogin();
				if (!isLogin) {
					// this.$router.push("/login");
					// return
				}
				if (!this.finished) {
					this.doRequestProxyStg();
				}
			},
			doRequestProxyStg() {
				request.post("/api/proxyStg/list").then(res => {
					this.dataList = res;
					this.finished = true;
				});
			},
			setProxyStg(item){
				this.storage.set("proxyStg", JSON.stringify(item));
				this.$router.go(-1);
			}
		},
		mounted() {
			this.containerHeight = document.documentElement.clientHeight - 100 + "px";
		}
		

	}
</script>
<style type="text/css" scoped="">
	
	.listDetails {
		display: flex;
		padding-left: 10px;
		align-items: center;
		margin-top: 10px;
		background-color: #fff;
		padding:14px 10px;
	}
	.listDetails img{
		width: 100%;
		height:100%;
		border-radius: 5px;
	}
	.shopImg {
		width: 150px;
		margin: 0 10px;
	}

	.shopImg img {
		width: 140px;
		height: 140px;
		border-radius: 10px;

	}

	.van-grid-item {
		width: 33.33%;
		flex-basis: 2px !important;
	}

	.van-cell__right-icon {
		color: #ccc;
		width: 30px;
		height: 15px;
		float: right;
		text-align: right;
		margin-top: -5px;
	}

	.goodsTitle .p1 {
		font-weight: bold;
	}

	.goodsTitle .p2 {
		color: #9c9c9c;
		background: #fbfbfb;
		padding: 4px 2px;
		height: 2.2em;
		overflow: hidden;
	}

	.van-grid-item {
		margin-bottom: 5px !important;
	}

	.title {
		font-size: 16px;
		font-weight: normal;
		padding: 15px;
	}

	.dayHistory img {
		width: 100%;
	}

	.goodsInfo {
		margin-top: 10px;
		width: 100%;
	}

	.goodsInfo {
		color: #ff6034;
	}
</style>